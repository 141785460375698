import {createI18n} from 'vue-i18n'
import LocalEN from './en'
import LocalTH from './th'
import LocalVI from './vi'
import LocalPT from './pt'
import LocalES from './es'
import LocalJA from './ja'
import LocalAR from './ar'
import LocalRU from './ru'
import LocalIT from './it'
import LocalFR from './fr'
import LocalDE from './de'
import LocalKO from './ko'

// 根据浏览器环境设置语言en: 英语（English）th: 泰语（Thai）vi: 越南语（Vietnamese）pt: 葡萄牙语（Portuguese）es: 西班牙语（Spanish）ja 日语 ar 阿拉伯语 ru 俄语 it 意大利语 fr 法语 de 德语

const language = navigator.language.toLowerCase();
console.log(language);

let whitelanguage = ['en','th','vi','pt','es','ja','ar','ru','it','fr','de','ko']
const SetLocal=()=>{
let response ='en'
if(whitelanguage.includes(language)){
  response=language
}else{
  whitelanguage.forEach(i=>{
    if(language.startsWith(i)){
      response=i
    }
  })
}

return response || 'en'
}

const i18n = createI18n({
  legacy: false,
  locale: SetLocal(),
  fallbackLocale: 'en',
  messages: {
    en: {
      ...LocalEN
    },
    vi:{
      ...LocalVI
    },
    th:{
      ...LocalTH
    },
    pt:{
      ...LocalPT
    },
    es:{
      ...LocalES
    },
    'ja':{ ...LocalJA},
    'ar':{ ...LocalAR},
    'ru':{ ...LocalRU},
    'it':{ ...LocalIT},
    'fr':{ ...LocalFR},
    'de':{ ...LocalDE},
    'ko':{...LocalKO}

  },
});


export default i18n