export default {
    "Shopping": {
        "pay": "دفع",
        "otherproduct": "منتج آخر",
        "otherproducts": "منتجات أخرى",
        "qty": "الكمية",
        "patpat": "شراء من PatPat",
        "each": "لكل",
        "showall": "عرض الكل",
        "items": "العناصر",
        "showless": "عرض أقل",
        "subtotal": "الإجمالي الفرعي",
        "totaldue": "الإجمالي المستحق",
        "cashier": "أمين الصندوق",
        "and": "و"
    },
    "LeftCard": {
        "back": "عودة",
        "poweredby": "مشغل بواسطة",
        "terms": "الشروط",
        "privacy": "الخصوصية"
    },
    "TopCard": {
        "details": "التفاصيل",
        "close": "إغلاق"
    },
    "PaymentMethod": {
        "paymentmethod": "طريقة الدفع"
    },
    "ContactInformation": {
        "contactinformation": "معلومات الاتصال",
        "shippingaddress": "عنوان الشحن",
        "country": "البلد",
        "address1": "سطر العنوان 1",
        "address2": "سطر العنوان 2",
        "name": "الاسم",
        "billingaddress": "عنوان الفواتير",
        "phone": "الهاتف",
        "email": "البريد الإلكتروني",
        "FullName": "الاسم الكامل",
        "CPFNumber": "رقم CPF"
    },
    "ButtunMessage": {
        "processing": "جارٍ المعالجة...",
        "wechatPC": "إنشاء رمز QR لـ",
        "pay": "دفع"
    },
    "ErrorMessage": {
        "confirm": "تأكيد",
        "FullNameerr": "الاسم الكامل مطلوب.",
        "CPFNumbererr": "رقم CPF مطلوب.",
        "Countryerr": "البلد مطلوب.",
        "CPFIerr": "يرجى اتباع التنسيق: XXX.XXX.XXX-XX أو 11 رقمًا بدون شرطات.",
        "CPFIVerr": "رقم CPF غير صالح. يرجى التحقق وإعادة الإدخال."
    },
    "Pix": {
        "ScanPix": "مسح أو نسخ الرمز للدفع باستخدام Pix",
        "tip": "بعد تقديم طلبك، امسح رمز QR باستخدام تطبيق البنك الخاص بك أو انسخ الرمز للدفع.",
        "Scanapp": "امسح للدفع باستخدام تطبيق البنك الخاص بك",
        "pixcodetip": "الصق رمز Pix تحت 'نسخ ولصق Pix' في بوابة الخدمات المصرفية عبر الإنترنت أو تطبيق البنك الخاص بك.",
        "pix_centip": "بعد الدفع الناجح، لا يزال يتطلب تأكيدًا من المزود. قد تستغرق هذه العملية عدة دقائق.",
        "pix_copytip": "أو انسخ الرمز للدفع",
        "pleasehtml": "يرجى إجراء الدفع في"
    },
    "Copy": {
        "success": "تم النسخ إلى الحافظة",
        "copy": "نسخ"
    },
    "CardInformation": {
        "CardInformation": "معلومات البطاقة",
        "NameOnCard": "الاسم على البطاقة",
        "Name": "الاسم",
        "Nameerr": "الاسم على البطاقة مطلوب.",
        "Expirationerr": "تاريخ انتهاء صلاحية البطاقة غير مكتمل.",
        "InvalidExpiration": "تاريخ انتهاء صلاحية غير صالح.",
        "pastExpiration": "تاريخ انتهاء صلاحية البطاقة في الماضي.",
        "Carderr": "رقم البطاقة غير مكتمل.",
        "NumberErr": "رقم البطاقة غير صالح",
        "cvcerr": "رمز أمان البطاقة غير مكتمل.",
        "InvalidCVCCode": "رمز CVC غير صالح."
    },
    "BillingAddress": {
        "BillingAddress": "عنوان الفواتير",
        "BS": "عنوان الفواتير هو نفس عنوان الشحن",
        "AL1": "سطر العنوان 1",
        "AL2": "سطر العنوان 2",
        "City": "المدينة",
        "Postalcode": "الرمز البريدي",
        "State": "الولاية",
        "Email": "البريد الإلكتروني",
        "Phone": "الهاتف",
        "Phonenumber": "رقم الهاتف"
    },
    "ShippingAddress": {
        "Name": "الاسم",
        "AL1": "سطر العنوان 1",
        "AL2": "سطر العنوان 2",
        "City": "المدينة",
        "Postalcode": "الرمز البريدي",
        "State": "الولاية",
        "ShippingAddress": "عنوان الشحن"
    },
    "FormErr": {
        "l1": "السطر 1 مطلوب.",
        "l2": "السطر 2 مطلوب.",
        "Phoneerr": "الهاتف مطلوب.",
        "ie": "البريد الإلكتروني غير صالح.",
        "er": "البريد الإلكتروني مطلوب.",
        "cr": "المدينة مطلوبة.",
        "pr": "الرمز البريدي مطلوب.",
        "sr": "الولاية مطلوبة.",
        "nr": "الاسم مطلوب.",
        "cor": "البلد مطلوب."
    },
    "Latest250114": {
        "Viewdetails":'عرض التفاصيل',
        "Thanksforyourpayment": "شكرًا لدفعك",
        "Bankname": "اسم البنك",
        "Alipayselected": "تم اختيار Alipay.",
        "WeChatPayselected": "تم اختيار WeChat Pay.",
        "Asyoctpu": "بعد تقديم طلبك، أكمل الدفع باستخدام",
        "Asyostqcu": "بعد تقديم طلبك، امسح رمز QR باستخدام",
        "Starttrial": "ابدأ التجربة",
        "Subscribe": "اشتراك",
        "Continue": "متابعة",
        "NewMemberCoupon": "قسيمة العضو الجديد",
        "Addpromotioncode": "إضافة رمز ترويجي",
        "Apply": "تطبيق",
        "Subscribeto": "اشترك في",
        "free": " مجاني ",
        "days": " أيام ",
        "day": " يوم ",
        "Then": " ثم ",
        "forthefirstbilling": "لأول دورة فوترة ",
        "cycles": " دورات ",
        "cycle": " دورة ",
        "off": " خصم ",
        "today": " اليوم ",
        "Totalaftertrial": "الإجمالي بعد التجربة",
        "Totalduetoday": "الإجمالي المستحق اليوم",
        "after": " بعد "
    }
}
