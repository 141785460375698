export default {
    "Shopping": {
        "pay": "Pagar",
        "otherproduct": "outro produto",
        "otherproducts": "outros produtos",
        "qty": "Quantidade",
        "patpat": "Comprar na PatPat",
        "each": "cada",
        "showall": "Mostrar todos",
        "items": "itens",
        "showless": "Mostrar menos",
        "subtotal": "Subtotal",
        "totaldue": "Total devido",
        "cashier": "Caixa",
        "and": "e"
    },
    "LeftCard": {
        "back": "Voltar",
        "poweredby": "Desenvolvido por",
        "terms": "Termos",
        "privacy": "Privacidade"
    },
    "TopCard": {
        "details": "Detalhes",
        "close": "Fechar"
    },
    "PaymentMethod": {
        "paymentmethod": "Método de pagamento"
    },
    "ContactInformation": {
        "contactinformation": "Informações de contato",
        "shippingaddress": "Endereço de entrega",
        "country": "País",
        "address1": "Endereço linha 1",
        "address2": "Endereço linha 2",
        "name": "Nome",
        "billingaddress": "Endereço de cobrança",
        "phone": "Telefone",
        "email": "Email",
        "FullName": "Nome completo",
        "CPFNumber": "Cadastro de Pessoas Físicas"
    },
    "ButtunMessage": {
        "processing": "Processando...",
        "wechatPC": "Gerar código QR para",
        "pay": "Pagar",
    },
    "ErrorMessage": {
        "confirm": "Confirmar",
        "FullNameerr": "O nome completo é obrigatório.",
        "Countryerr": "O país é obrigatório",
        "CPFNumbererr": "O número do CPF é obrigatório.",
        "CPFIerr": "Por favor, siga o formato: XXX.XXX.XXX-XX ou 11 dígitos sem traços.",
        "CPFIVerr": "Número de CPF inválido. Por favor, verifique e digite novamente."
    },
    "Pix": {
        "ScanPix": "Escaneie ou copie o código para pagar com Pix",
        "tip": "Após enviar seu pedido, escaneie o código QR com o aplicativo bancário ou copie o código para efetuar o pagamento.",
        "Scanapp": "Escanear para pagar com o aplicativo do seu banco.",
        "pixcodetip": "Cole o código Pix na seção 'Copia e cola Pix' no seu portal de internet banking ou aplicativo bancário.",
        "pix_centip": "Após um pagamento bem-sucedido, ainda é necessária uma confirmação do provedor. Este processo pode levar alguns minutos.",
        "pix_copytip": "Ou copie o código para pagar",
        "pleasehtml": "Por favor, efetue o pagamento em"
    },
    "Copy": {
        "success": "Copiado para a área de transferência",
        "copy": "Copiar"
    },
    "CardInformation": {
        "CardInformation": "Informações do cartão",
        "NameOnCard": "Nome no cartão",
        "Name": "Nome",
        "Nameerr": "Nome no cartão é obrigatório.",
        "Expirationerr": "A data de validade do seu cartão está incompleta.",
        "InvalidExpiration": "Data de validade inválida.",
        "pastExpiration": "A data de validade do seu cartão está no passado.",
        "Carderr": "O número do seu cartão está incompleto.",
        "NumberErr": "Número do cartão inválido",
        "cvcerr": "O código de segurança do seu cartão está incompleto.",
        "InvalidCVCCode": "Código CVC inválido."
    },
    "BillingAddress": {
        "BillingAddress": "Endereço de cobrança",
        "BS": "Endereço de cobrança igual ao de entrega",
        "AL1": "Linha do endereço 1",
        "AL2": "Linha do endereço 2",
        "City": "Cidade",
        "Postalcode": "Código postal",
        "State": "Estado",
        "Email": "Email",
        "Phone": "telefone",
        "Phonenumber": "Número de telefone"
    },
    "ShippingAddress": {
        "Name": "Nome",
        "AL1": "Linha do endereço 1",
        "AL2": "Linha do endereço 2",
        "City": "Cidade",
        "Postalcode": "Código postal",
        "State": "Estado",
        "ShippingAddress": "Endereço de entrega"
    },
    "CountyCode": {
        "CountyUrl": []
    },
    "FormErr": {
        "l1": "Linha do endereço 1 é obrigatória.",
        "l2": "Linha do endereço 2 é obrigatória.",
        "Phoneerr": "Telefone é obrigatório.",
        "ie": "Email inválido.",
        "er": "Email é obrigatório.",
        "cr": "Cidade é obrigatória.",
        "pr": "Código postal é obrigatório.",
        "sr": "Estado é obrigatório.",
        "nr": "Nome é obrigatório.",
        "cor": "País é obrigatório."
    },
    Latest250114:{
    "Viewdetails":'Ver detalhes',
    'Thanksforyourpayment': 'Obrigado pelo seu pagamento',
    'Bankname': 'Nome do banco',
    'Alipayselected': 'Alipay selecionado.',
    'WeChatPayselected': 'WeChat Pay selecionado.',
    'Asyoctpu': 'Após enviar seu pedido, complete o pagamento usando',
    'Asyostqcu': 'Após enviar seu pedido, escaneie o código QR usando',
    'Starttrial': 'Iniciar teste',
    'Subscribe': 'Assinar',
    'Continue': 'Continuar',
    'NewMemberCoupon': 'Cupom para novo membro',
    'Addpromotioncode': 'Adicionar código promocional',
    'Apply': 'Aplicar',
    'Subscribeto': 'Assinar para',
    'free': ' grátis ',
    'days': ' dias ',
    'day': ' dia ',
    'Then': ' Então ',
    'forthefirstbilling': 'para o primeiro ciclo de faturamento',
    'cycles': ' ciclos ',
    'cycle': ' ciclo ',
    'off': ' de desconto ',
    'today': ' hoje ',
    'Totalaftertrial': 'Total após o teste',
    'Totalduetoday': 'Total devido hoje',
    "after": ' após '
      },
}