export default {
    Shopping: {
        pay: 'Pagar',
        otherproduct: 'otro producto',
        otherproducts: 'otros productos',
        qty: 'Cant.',
        patpat: 'Comprar en PatPat',
        each: 'cada uno',
        showall: 'Mostrar todo',
        items: 'artículos',
        showless: 'Mostrar menos',
        subtotal: 'Subtotal',
        totaldue: 'Total a pagar',
        cashier: 'Cajero',
        and: 'y'
    },
    LeftCard: {
        back: 'Atrás',
        poweredby: 'Desarrollado por',
        terms: 'Términos',
        privacy: 'Privacidad'
    },
    TopCard: {
        details: 'Detalles',
        close: 'Cerrar'
    },
    PaymentMethod: {
        paymentmethod: 'Método de pago'
    },
    ContactInformation: {
        contactinformation: 'Información de contacto',
        shippingaddress: 'Dirección de envío',
        country: 'País',
        address1: 'Línea de dirección 1',
        address2: 'Línea de dirección 2',
        name: 'Nombre',
        billingaddress: 'Dirección de facturación',
        phone: 'Teléfono',
        email: 'Correo electrónico',
        FullName: 'Nombre completo',
        CPFNumber: 'Número de CPF'
    },
    ButtunMessage: {
        processing: 'Procesando...',
        wechatPC: 'Generar código QR para',
        pay: 'Pagar'
    },
    ErrorMessage: {
        confirm: 'Confirmar',
        FullNameerr: 'Se requiere nombre completo.',
        CPFNumbererr: 'Se requiere número de CPF.',
        Countryerr: 'Se requiere país.',
        CPFIerr: 'Por favor, siga el formato: XXX.XXX.XXX-XX o 11 dígitos sin guiones.',
        CPFIVerr: 'Número de CPF no válido. Por favor, verifique y vuelva a ingresar.'
    },
    Pix: {
        ScanPix: 'Escanee o copie el código para pagar con Pix',
        tip: 'Después de enviar su pedido, escanee el código QR con su aplicación bancaria o copie el código para pagar.',
        Scanapp: 'Escanear para pagar con su aplicación bancaria',
        pixcodetip: 'Pegue el código Pix bajo "Pix copiar y pegar" en su portal de banca por internet o aplicación bancaria.',
        pix_centip: 'Después de un pago exitoso, aún necesita una confirmación del proveedor. Este proceso puede tardar hasta varios minutos.',
        pix_copytip: 'O copie el código para pagar',
        pleasehtml: 'Por favor, realice el pago en'
    },
    Copy: {
        success: 'Copiado al portapapeles',
        copy: 'Copiar'
    },
    "CardInformation": {
        "CardInformation": "Información de la tarjeta",
        "NameOnCard": "Nombre en la tarjeta",
        "Name": "Nombre",
        "Nameerr": "Se requiere el nombre en la tarjeta.",
        "Expirationerr": "La fecha de expiración de su tarjeta está incompleta.",
        "InvalidExpiration": "Fecha de expiración inválida.",
        "pastExpiration": "La fecha de expiración de su tarjeta está en el pasado.",
        "Carderr": "El número de su tarjeta está incompleto.",
        "NumberErr": "El número de su tarjeta no es válido",
        "cvcerr": "El código de seguridad de su tarjeta está incompleto.",
        "InvalidCVCCode": "Código CVC inválido."
    },
    "BillingAddress": {
        "BillingAddress": "Dirección de facturación",
        "BS": "La dirección de facturación es la misma que la de envío",
        "AL1": "Línea de dirección 1",
        "AL2": "Línea de dirección 2",
        "City": "Ciudad",
        "Postalcode": "Código postal",
        "State": "Provincia",
        "Email": "Correo electrónico",
        "Phone": "Teléfono",
        "Phonenumber": "Número de teléfono"
    },
    "ShippingAddress": {
        "Name": "Nombre",
        "AL1": "Línea de dirección 1",
        "AL2": "Línea de dirección 2",
        "City": "Ciudad",
        "Postalcode": "Código postal",
        "State": "Provincia",
        "ShippingAddress": "Dirección de envío"
    },
    "CountyCode": {
        "CountyUrl": []
    },
    "FormErr": {
        "l1": "Se requiere línea de dirección 1.",
        "l2": "Se requiere línea de dirección 2.",
        "Phoneerr": "Se requiere teléfono.",
        "ie": "Correo electrónico no válido.",
        "er": "Se requiere correo electrónico.",
        "cr": "Se requiere ciudad.",
        "pr": "Se requiere código postal.",
        "sr": "Se requiere provincia.",
        "nr": "Se requiere nombre.",
        "cor": "Se requiere país."
    },
    Latest250114:{
    "Viewdetails":'Ver detalles',
    'Thanksforyourpayment': 'Gracias por tu pago',
    'Bankname': 'Nombre del banco',
    'Alipayselected': 'Alipay seleccionado.',
    'WeChatPayselected': 'WeChat Pay seleccionado.',
    'Asyoctpu': 'Después de enviar tu pedido, completa el pago usando',
    'Asyostqcu': 'Después de enviar tu pedido, escanea el código QR usando',
    'Starttrial': 'Iniciar prueba',
    'Subscribe': 'Suscribirse',
    'Continue': 'Continuar',
    'NewMemberCoupon': 'Cupón para nuevos miembros',
    'Addpromotioncode': 'Añadir código promocional',
    'Apply': 'Aplicar',
    'Subscribeto': 'Suscribirse a',
    'free': ' gratis ',
    'days': ' días ',
    'day': ' día ',
    'Then': ' Luego ',
    'forthefirstbilling': 'para el primer ciclo de facturación',
    'cycles': ' ciclos ',
    'cycle': ' ciclo ',
    'off': ' de descuento ',
    'today': ' hoy ',
    'Totalaftertrial': 'Total después de la prueba',
    'Totalduetoday': 'Total a pagar hoy',
    "after": ' después '
      },
}
