export default {
    Shopping:{
        pay:'支払う',
        otherproduct:'他の商品',
        otherproducts:'他の商品',
        qty:'数量',
        patpat:'PatPatで購入',
        each:'各',
        showall:'すべて表示',
        items:'アイテム',
        showless:'表示を減らす',
        subtotal:'小計',
        totaldue:'支払合計',
        cashier:'レジ',
        and:'そして'
    },
    LeftCard:{
        back:'戻る',
        poweredby:'提供元',
        terms:'利用規約',
        privacy:'プライバシー',
    },
    TopCard:{
        details:'詳細',
        close:'閉じる'
    },
    PaymentMethod:{
        paymentmethod :'支払方法',
    },
    ContactInformation:{
        contactinformation:'連絡先情報',
        shippingaddress:'配送先住所',
        country:'国',
        address1:'住所1',
        address2:'住所2',
        name:'名前',
        billingaddress:'請求先住所',
        phone:'電話',
        email:'メール',
        FullName:'フルネーム',
        CPFNumber:'CPF番号',
    },
    ButtunMessage:{
        processing:'処理中...',
        wechatPC:'QRコードを生成中',
        pay:'支払う',
    },
    ErrorMessage:{
        confirm:'確認',
        FullNameerr:'フルネームは必須です。',
        CPFNumbererr:'CPF番号は必須です。',
        Countryerr :'国は必須です。',
        CPFIerr:'形式に従ってください：XXX.XXX.XXX-XXまたはダッシュなしの11桁。',
        CPFIVerr:'無効なCPF番号です。確認して再入力してください。'
    },
    Pix:{
        ScanPix:'スキャンまたはコードをコピーしてPixで支払う',
        tip:'注文を送信した後、QRコードをスキャンするか、コードをコピーして支払ってください。',
        Scanapp:'銀行アプリでスキャンして支払う',
        pixcodetip:'インターネットバンキングポータルまたは銀行アプリで「Pixコピー＆ペースト」の下にPixコードを貼り付けてください。',
        pix_centip:'支払いが成功すると、プロバイダーからの確認が必要です。このプロセスには数分かかる場合があります。',
        pix_copytip:'またはコードをコピーして支払う',
        pleasehtml:'以下の期間内にお支払いください'
    },
    Copy:{
        success:'クリップボードにコピーしました',
        copy:'コピー'
    },
    CardInformation:{
        CardInformation:'カード情報',
        NameOnCard:'カード名義人',
        Name:'名前',
        Nameerr:'カード名義人は必須です。',
        Expirationerr:'カードの有効期限が不完全です。',
        InvalidExpiration:'無効な有効期限です。',
        pastExpiration:'カードの有効期限が過ぎています。',
        Carderr:'カード番号が不完全です。',
        NumberErr:'カード番号が無効です。',
        cvcerr:'カードのセキュリティコードが不完全です。',
        InvalidCVCCode:'無効なCVCコードです。'
    },
    BillingAddress:{
        BillingAddress:'請求先住所',
        BS:'請求先住所は配送先と同じ',
        AL1:'住所1',
        AL2:'住所2',
        City:'市区町村',
        Postalcode:'郵便番号',
        State:'都道府県',
        Email:'メール',
        Phone:'電話',
        Phonenumber:'電話番号'
    },
    ShippingAddress:{
        Name:'名前',
        AL1:'住所1',
        AL2:'住所2',
        City:'市区町村',
        Postalcode:'郵便番号',
        State:'都道府県',
        ShippingAddress:'配送先住所'
    },
    FormErr:{
        l1:'行1は必須です。',
        l2:'行2は必須です。',
        Phoneerr:'電話は必須です。',
        ie:'無効なメールアドレスです。',
        er:'メールは必須です。',
        cr:'市区町村は必須です。',
        pr:'郵便番号は必須です。',
        sr:'都道府県は必須です。',
        nr:'名前は必須です。',
        cor:'国は必須です。'
    },
    Latest250114:{
      "Viewdetails":'詳細を見る',
      'Thanksforyourpayment':'お支払いありがとうございます',
      'Bankname':'銀行名',
      'Alipayselected':'Alipayが選択されました。',
      'WeChatPayselected':'WeChat Payが選択されました。',
      'Asyoctpu':'注文を送信した後、以下を使用して支払いを完了してください',
      'Asyostqcu':'注文を送信した後、QRコードを使用してスキャンしてください',
      'Starttrial':'無料体験を開始',
      'Subscribe':'購読する',
      'Continue':'続ける',
      'NewMemberCoupon':'新規会員クーポン',
      'Addpromotioncode':'プロモーションコードを追加',
      'Apply':'適用',
      'Subscribeto':'購読する',
      'free':'無料',
      'days':'日',
      'day':'日',
      'Then':'その後',
      'forthefirstbilling':'初回請求で',
      'cycles':'サイクル',
      'cycle':'サイクル',
      'off':'割引',
      'today':'本日',
      'Totalaftertrial':'試用後の合計',
      'Totalduetoday':'今日の合計支払額',
      'after':'後'
    }
}