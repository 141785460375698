export default {
    "Shopping": {
        "pay": "Bezahlen",
        "otherproduct": "anderes Produkt",
        "otherproducts": "andere Produkte",
        "qty": "Menge",
        "patpat": "Auf PatPat einkaufen",
        "each": "je",
        "showall": "Alles anzeigen",
        "items": "Artikel",
        "showless": "Weniger anzeigen",
        "subtotal": "Zwischensumme",
        "totaldue": "Gesamtbetrag",
        "cashier": "Kassierer",
        "and": "und"
    },
    "LeftCard": {
        "back": "Zurück",
        "poweredby": "Bereitgestellt von",
        "terms": "Bedingungen",
        "privacy": "Datenschutz"
    },
    "TopCard": {
        "details": "Details",
        "close": "Schließen"
    },
    "PaymentMethod": {
        "paymentmethod": "Zahlungsmethode"
    },
    "ContactInformation": {
        "contactinformation": "Kontaktinformationen",
        "shippingaddress": "Lieferadresse",
        "country": "Land",
        "address1": "Adresszeile 1",
        "address2": "Adresszeile 2",
        "name": "Name",
        "billingaddress": "Rechnungsadresse",
        "phone": "Telefon",
        "email": "E-Mail",
        "FullName": "Vollständiger Name",
        "CPFNumber": "CPF-Nummer"
    },
    "ButtunMessage": {
        "processing": "Wird verarbeitet...",
        "wechatPC": "QR-Code wird erstellt für",
        "pay": "Bezahlen"
    },
    "ErrorMessage": {
        "confirm": "Bestätigen",
        "FullNameerr": "Der vollständige Name ist erforderlich.",
        "CPFNumbererr": "Die CPF-Nummer ist erforderlich.",
        "Countryerr": "Das Land ist erforderlich.",
        "CPFIerr": "Bitte im Format eingeben: XXX.XXX.XXX-XX oder 11 Ziffern ohne Bindestriche.",
        "CPFIVerr": "Ungültige CPF-Nummer. Bitte überprüfen und erneut eingeben."
    },
    "Pix": {
        "ScanPix": "Scannen oder kopieren Sie den Code, um mit Pix zu bezahlen",
        "tip": "Nachdem Sie Ihre Bestellung abgeschickt haben, scannen Sie den QR-Code mit Ihrer Banking-App oder kopieren Sie den Code, um zu bezahlen.",
        "Scanapp": "Scannen, um mit der Banking-App zu bezahlen",
        "pixcodetip": "Fügen Sie den Pix-Code unter 'Pix kopieren und einfügen' im Online-Banking-Portal oder in Ihrer Banking-App ein.",
        "pix_centip": "Nach einer erfolgreichen Zahlung ist eine Bestätigung des Anbieters erforderlich. Dieser Vorgang kann einige Minuten dauern.",
        "pix_copytip": "Oder kopieren Sie den Code, um zu bezahlen",
        "pleasehtml": "Bitte bezahlen Sie innerhalb von"
    },
    "Copy": {
        "success": "In die Zwischenablage kopiert",
        "copy": "Kopieren"
    },
    "CardInformation": {
        "CardInformation": "Karteninformationen",
        "NameOnCard": "Name auf der Karte",
        "Name": "Name",
        "Nameerr": "Der Name auf der Karte ist erforderlich.",
        "Expirationerr": "Das Ablaufdatum der Karte ist unvollständig.",
        "InvalidExpiration": "Ungültiges Ablaufdatum.",
        "pastExpiration": "Das Ablaufdatum der Karte liegt in der Vergangenheit.",
        "Carderr": "Die Kartennummer ist unvollständig.",
        "NumberErr": "Ungültige Kartennummer.",
        "cvcerr": "Der Sicherheitscode der Karte ist unvollständig.",
        "InvalidCVCCode": "Ungültiger CVC-Code."
    },
    "BillingAddress": {
        "BillingAddress": "Rechnungsadresse",
        "BS": "Die Rechnungsadresse ist identisch mit der Lieferadresse",
        "AL1": "Adresszeile 1",
        "AL2": "Adresszeile 2",
        "City": "Stadt",
        "Postalcode": "Postleitzahl",
        "State": "Bundesland",
        "Email": "E-Mail",
        "Phone": "Telefon",
        "Phonenumber": "Telefonnummer"
    },
    "ShippingAddress": {
        "Name": "Name",
        "AL1": "Adresszeile 1",
        "AL2": "Adresszeile 2",
        "City": "Stadt",
        "Postalcode": "Postleitzahl",
        "State": "Bundesland",
        "ShippingAddress": "Lieferadresse"
    },
    "FormErr": {
        "l1": "Adresszeile 1 ist erforderlich.",
        "l2": "Adresszeile 2 ist erforderlich.",
        "Phoneerr": "Telefon ist erforderlich.",
        "ie": "Ungültige E-Mail-Adresse.",
        "er": "E-Mail ist erforderlich.",
        "cr": "Stadt ist erforderlich.",
        "pr": "Postleitzahl ist erforderlich.",
        "sr": "Bundesland ist erforderlich.",
        "nr": "Name ist erforderlich.",
        "cor": "Land ist erforderlich."
    },
    "Latest250114": {
        "Viewdetails":'Details anzeigen',
        "Thanksforyourpayment": "Vielen Dank für Ihre Zahlung",
        "Bankname": "Bankname",
        "Alipayselected": "Alipay ausgewählt.",
        "WeChatPayselected": "WeChat Pay ausgewählt.",
        "Asyoctpu": "Nachdem Sie Ihre Bestellung abgeschickt haben, schließen Sie die Zahlung mit",
        "Asyostqcu": "Nachdem Sie Ihre Bestellung abgeschickt haben, scannen Sie den QR-Code mit",
        "Starttrial": "Testversion starten",
        "Subscribe": "Abonnieren",
        "Continue": "Weiter",
        "NewMemberCoupon": "Neumitglieds-Gutschein",
        "Addpromotioncode": "Promotion-Code hinzufügen",
        "Apply": "Anwenden",
        "Subscribeto": "Abonnieren bei",
        "free": " kostenlos ",
        "days": " Tage ",
        "day": " Tag ",
        "Then": " Dann ",
        "forthefirstbilling": "für den ersten Abrechnungszyklus ",
        "cycles": " Zyklen ",
        "cycle": " Zyklus ",
        "off": " Rabatt ",
        "today": " heute ",
        "Totalaftertrial": "Gesamt nach der Testphase",
        "Totalduetoday": "Heute fälliger Betrag",
        "after": " nach "
    }
}
