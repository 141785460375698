export default {
    "Shopping": {
        "pay": "Оплатить",
        "otherproduct": "другой продукт",
        "otherproducts": "другие продукты",
        "qty": "Кол-во",
        "patpat": "Купить на PatPat",
        "each": "каждый",
        "showall": "Показать все",
        "items": "товары",
        "showless": "Показать меньше",
        "subtotal": "Промежуточный итог",
        "totaldue": "Всего к оплате",
        "cashier": "Кассир",
        "and": "и"
    },
    "LeftCard": {
        "back": "Назад",
        "poweredby": "Работает на",
        "terms": "Условия",
        "privacy": "Конфиденциальность"
    },
    "TopCard": {
        "details": "Детали",
        "close": "Закрыть"
    },
    "PaymentMethod": {
        "paymentmethod": "Способ оплаты"
    },
    "ContactInformation": {
        "contactinformation": "Контактная информация",
        "shippingaddress": "Адрес доставки",
        "country": "Страна",
        "address1": "Адрес, строка 1",
        "address2": "Адрес, строка 2",
        "name": "Имя",
        "billingaddress": "Платежный адрес",
        "phone": "Телефон",
        "email": "Электронная почта",
        "FullName": "Полное имя",
        "CPFNumber": "Номер CPF"
    },
    "ButtunMessage": {
        "processing": "Обработка...",
        "wechatPC": "Создание QR-кода для",
        "pay": "Оплатить"
    },
    "ErrorMessage": {
        "confirm": "Подтвердить",
        "FullNameerr": "Полное имя обязательно.",
        "CPFNumbererr": "Номер CPF обязателен.",
        "Countryerr": "Страна обязательна.",
        "CPFIerr": "Пожалуйста, следуйте формату: XXX.XXX.XXX-XX или 11 цифр без тире.",
        "CPFIVerr": "Недействительный номер CPF. Пожалуйста, проверьте и введите снова."
    },
    "Pix": {
        "ScanPix": "Сканируйте или скопируйте код для оплаты через Pix",
        "tip": "После отправки заказа отсканируйте QR-код с помощью банковского приложения или скопируйте код для оплаты.",
        "Scanapp": "Сканируйте для оплаты с помощью банковского приложения",
        "pixcodetip": "Вставьте код Pix в разделе 'Pix копирование и вставка' на портале интернет-банкинга или в банковском приложении.",
        "pix_centip": "После успешной оплаты требуется подтверждение от провайдера. Этот процесс может занять несколько минут.",
        "pix_copytip": "Или скопируйте код для оплаты",
        "pleasehtml": "Пожалуйста, произведите оплату в течение"
    },
    "Copy": {
        "success": "Скопировано в буфер обмена",
        "copy": "Копировать"
    },
    "CardInformation": {
        "CardInformation": "Информация о карте",
        "NameOnCard": "Имя на карте",
        "Name": "Имя",
        "Nameerr": "Имя на карте обязательно.",
        "Expirationerr": "Срок действия карты не указан полностью.",
        "InvalidExpiration": "Недействительная дата окончания срока действия.",
        "pastExpiration": "Срок действия карты истёк.",
        "Carderr": "Номер карты не указан полностью.",
        "NumberErr": "Недействительный номер карты.",
        "cvcerr": "Код безопасности карты не указан полностью.",
        "InvalidCVCCode": "Недействительный код CVC."
    },
    "BillingAddress": {
        "BillingAddress": "Платежный адрес",
        "BS": "Платежный адрес совпадает с адресом доставки",
        "AL1": "Адрес, строка 1",
        "AL2": "Адрес, строка 2",
        "City": "Город",
        "Postalcode": "Почтовый индекс",
        "State": "Штат",
        "Email": "Электронная почта",
        "Phone": "Телефон",
        "Phonenumber": "Номер телефона"
    },
    "ShippingAddress": {
        "Name": "Имя",
        "AL1": "Адрес, строка 1",
        "AL2": "Адрес, строка 2",
        "City": "Город",
        "Postalcode": "Почтовый индекс",
        "State": "Штат",
        "ShippingAddress": "Адрес доставки"
    },
    "FormErr": {
        "l1": "Строка 1 обязательна.",
        "l2": "Строка 2 обязательна.",
        "Phoneerr": "Телефон обязателен.",
        "ie": "Недействительный адрес электронной почты.",
        "er": "Электронная почта обязательна.",
        "cr": "Город обязателен.",
        "pr": "Почтовый индекс обязателен.",
        "sr": "Штат обязателен.",
        "nr": "Имя обязательно.",
        "cor": "Страна обязательна."
    },
    "Latest250114": {
        "Thanksforyourpayment": "Спасибо за ваш платеж",
        "Bankname": "Название банка",
        "Alipayselected": "Выбран Alipay.",
        "WeChatPayselected": "Выбран WeChat Pay.",
        "Asyoctpu": "После оформления заказа завершите оплату с помощью",
        "Asyostqcu": "После оформления заказа отсканируйте QR-код с помощью",
        "Starttrial": "Начать пробный период",
        "Subscribe": "Подписаться",
        "Continue": "Продолжить",
        "NewMemberCoupon": "Купон нового участника",
        "Addpromotioncode": "Добавить промокод",
        "Apply": "Применить",
        "Subscribeto": "Подписаться на",
        "free": " бесплатно ",
        "days": " дней ",
        "day": " день ",
        "Then": " Затем ",
        "forthefirstbilling": "для первого биллинга ",
        "cycles": " циклы ",
        "cycle": " цикл ",
        "off": " скидка ",
        "today": " сегодня ",
        "Totalaftertrial": "Итого после пробного периода",
        "Totalduetoday": "Итого к оплате сегодня",
        "Viewdetails":'Посмотреть детали',
        "after": " после "
    }
}

