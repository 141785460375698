export default {
    "Shopping": {
        "pay": "결제",
        "otherproduct": "기타 제품",
        "otherproducts": "기타 제품들",
        "qty": "수량",
        "patpat": "PatPat에서 구매",
        "each": "각각",
        "showall": "모두 보기",
        "items": "아이템",
        "showless": "적게 보기",
        "subtotal": "소계",
        "totaldue": "총 결제액",
        "cashier": "계산대",
        "and": "그리고"
    },
    "LeftCard": {
        "back": "뒤로",
        "poweredby": "제공",
        "terms": "이용 약관",
        "privacy": "개인정보 보호"
    },
    "TopCard": {
        "details": "상세",
        "close": "닫기"
    },
    "PaymentMethod": {
        "paymentmethod": "결제 방법"
    },
    "ContactInformation": {
        "contactinformation": "연락처 정보",
        "shippingaddress": "배송 주소",
        "country": "국가",
        "address1": "주소 1",
        "address2": "주소 2",
        "name": "이름",
        "billingaddress": "청구 주소",
        "phone": "전화",
        "email": "이메일",
        "FullName": "전체 이름",
        "CPFNumber": "CPF 번호"
    },
    "ButtunMessage": {
        "processing": "처리 중...",
        "wechatPC": "QR 코드 생성 중",
        "pay": "결제"
    },
    "ErrorMessage": {
        "confirm": "확인",
        "FullNameerr": "전체 이름은 필수입니다.",
        "CPFNumbererr": "CPF 번호는 필수입니다.",
        "Countryerr": "국가는 필수입니다.",
        "CPFIerr": "형식에 맞게 입력해 주세요: XXX.XXX.XXX-XX 또는 대시 없이 11자리 숫자.",
        "CPFIVerr": "유효하지 않은 CPF 번호입니다. 확인 후 다시 입력해 주세요."
    },
    "Pix": {
        "ScanPix": "Pix로 결제하려면 코드 스캔 또는 복사",
        "tip": "주문을 제출한 후 은행 앱으로 QR 코드를 스캔하거나 코드를 복사하여 결제하세요.",
        "Scanapp": "은행 앱으로 결제하려면 스캔하세요.",
        "pixcodetip": "인터넷 뱅킹 또는 은행 앱의 'Pix 복사 및 붙여넣기'에 Pix 코드를 붙여넣으세요.",
        "pix_centip": "결제가 완료된 후 제공자로부터 확인이 필요합니다. 이 과정은 몇 분이 걸릴 수 있습니다.",
        "pix_copytip": "또는 코드를 복사하여 결제하세요.",
        "pleasehtml": "결제를 진행하세요"
    },
    "Copy": {
        "success": "클립보드에 복사됨",
        "copy": "복사"
    },
    "CardInformation": {
        "CardInformation": "카드 정보",
        "NameOnCard": "카드 이름",
        "Name": "이름",
        "Nameerr": "카드 이름은 필수입니다.",
        "Expirationerr": "카드 만료일이 불완전합니다.",
        "InvalidExpiration": "유효하지 않은 만료일입니다.",
        "pastExpiration": "카드 만료일이 지난 날짜입니다.",
        "Carderr": "카드 번호가 불완전합니다.",
        "NumberErr": "카드 번호가 유효하지 않습니다.",
        "cvcerr": "카드 보안 코드가 불완전합니다.",
        "InvalidCVCCode": "유효하지 않은 CVC 코드입니다."
    },
    "BillingAddress": {
        "BillingAddress": "청구 주소",
        "BS": "배송지와 동일한 청구 주소",
        "AL1": "주소 1",
        "AL2": "주소 2",
        "City": "도시",
        "Postalcode": "우편 번호",
        "State": "주",
        "Email": "이메일",
        "Phone": "전화",
        "Phonenumber": "전화번호"
    },
    "ShippingAddress": {
        "Name": "이름",
        "AL1": "주소 1",
        "AL2": "주소 2",
        "City": "도시",
        "Postalcode": "우편 번호",
        "State": "주",
        "ShippingAddress": "배송 주소"
    },
    "FormErr": {
        "l1": "주소 1은 필수입니다.",
        "l2": "주소 2는 필수입니다.",
        "Phoneerr": "전화번호는 필수입니다.",
        "ie": "유효하지 않은 이메일입니다.",
        "er": "이메일은 필수입니다.",
        "cr": "도시는 필수입니다.",
        "pr": "우편 번호는 필수입니다.",
        "sr": "주는 필수입니다.",
        "nr": "이름은 필수입니다.",
        "cor": "국가는 필수입니다."
    },
    "Latest250114": {
        "Thanksforyourpayment": "결제해 주셔서 감사합니다",
        "Bankname": "은행 이름",
        "Alipayselected": "알리페이 선택됨.",
        "WeChatPayselected": "위챗페이 선택됨.",
        "Asyoctpu": "주문을 제출한 후",
        "Asyostqcu": "주문을 제출한 후 QR 코드를 스캔하세요.",
        "Starttrial": "체험 시작",
        "Subscribe": "구독",
        "Continue": "계속",
        "NewMemberCoupon": "새 회원 쿠폰",
        "Addpromotioncode": "프로모션 코드 추가",
        "Apply": "적용",
        "Subscribeto": "구독하기",
        "free": " 무료 ",
        "days": " 일 ",
        "day": " 일 ",
        "Then": " 그 후 ",
        "forthefirstbilling": "첫 결제 때 ",
        "cycles": " 주기 ",
        "cycle": " 주기 ",
        "off": " 할인 ",
        "today": " 오늘 ",
        "Totalaftertrial": "체험 후 총액",
        "Totalduetoday": "오늘 결제할 총액",
        "after": " 후 ",
        "Viewdetails": "상세보기"
    }
}
