export default {
    "Shopping": {
        "pay": "Paga",
        "otherproduct": "altro prodotto",
        "otherproducts": "altri prodotti",
        "qty": "Quantità",
        "patpat": "Acquista su PatPat",
        "each": "ciascuno",
        "showall": "Mostra tutto",
        "items": "articoli",
        "showless": "Mostra meno",
        "subtotal": "Subtotale",
        "totaldue": "Totale dovuto",
        "cashier": "Cassiere",
        "and": "e"
    },
    "LeftCard": {
        "back": "Indietro",
        "poweredby": "Offerto da",
        "terms": "Termini",
        "privacy": "Privacy"
    },
    "TopCard": {
        "details": "Dettagli",
        "close": "Chiudi"
    },
    "PaymentMethod": {
        "paymentmethod": "Metodo di pagamento"
    },
    "ContactInformation": {
        "contactinformation": "Informazioni di contatto",
        "shippingaddress": "Indirizzo di spedizione",
        "country": "Paese",
        "address1": "Riga indirizzo 1",
        "address2": "Riga indirizzo 2",
        "name": "Nome",
        "billingaddress": "Indirizzo di fatturazione",
        "phone": "Telefono",
        "email": "E-mail",
        "FullName": "Nome completo",
        "CPFNumber": "Numero CPF"
    },
    "ButtunMessage": {
        "processing": "Elaborazione...",
        "wechatPC": "Creazione del codice QR per",
        "pay": "Paga"
    },
    "ErrorMessage": {
        "confirm": "Conferma",
        "FullNameerr": "Il nome completo è obbligatorio.",
        "CPFNumbererr": "Il numero CPF è obbligatorio.",
        "Countryerr": "Il paese è obbligatorio.",
        "CPFIerr": "Segui il formato: XXX.XXX.XXX-XX o 11 cifre senza trattini.",
        "CPFIVerr": "Numero CPF non valido. Controlla e reinserisci."
    },
    "Pix": {
        "ScanPix": "Scansiona o copia il codice per pagare con Pix",
        "tip": "Dopo aver inviato il tuo ordine, scansiona il codice QR con l'app della tua banca o copia il codice per pagare.",
        "Scanapp": "Scansiona per pagare con l'app della tua banca",
        "pixcodetip": "Incolla il codice Pix sotto 'Copia e incolla Pix' nel portale dell'home banking o nell'app della tua banca.",
        "pix_centip": "Dopo un pagamento riuscito, è ancora necessaria la conferma da parte del provider. Questo processo potrebbe richiedere alcuni minuti.",
        "pix_copytip": "Oppure copia il codice per pagare",
        "pleasehtml": "Si prega di effettuare il pagamento entro"
    },
    "Copy": {
        "success": "Copiato negli appunti",
        "copy": "Copia"
    },
    "CardInformation": {
        "CardInformation": "Informazioni sulla carta",
        "NameOnCard": "Nome sulla carta",
        "Name": "Nome",
        "Nameerr": "Il nome sulla carta è obbligatorio.",
        "Expirationerr": "La data di scadenza della carta non è completa.",
        "InvalidExpiration": "Data di scadenza non valida.",
        "pastExpiration": "La data di scadenza della carta è nel passato.",
        "Carderr": "Il numero della carta non è completo.",
        "NumberErr": "Numero della carta non valido.",
        "cvcerr": "Il codice di sicurezza della carta non è completo.",
        "InvalidCVCCode": "Codice CVC non valido."
    },
    "BillingAddress": {
        "BillingAddress": "Indirizzo di fatturazione",
        "BS": "L'indirizzo di fatturazione è lo stesso dell'indirizzo di spedizione",
        "AL1": "Riga indirizzo 1",
        "AL2": "Riga indirizzo 2",
        "City": "Città",
        "Postalcode": "CAP",
        "State": "Stato",
        "Email": "E-mail",
        "Phone": "Telefono",
        "Phonenumber": "Numero di telefono"
    },
    "ShippingAddress": {
        "Name": "Nome",
        "AL1": "Riga indirizzo 1",
        "AL2": "Riga indirizzo 2",
        "City": "Città",
        "Postalcode": "CAP",
        "State": "Stato",
        "ShippingAddress": "Indirizzo di spedizione"
    },
    "FormErr": {
        "l1": "La riga 1 è obbligatoria.",
        "l2": "La riga 2 è obbligatoria.",
        "Phoneerr": "Il telefono è obbligatorio.",
        "ie": "E-mail non valida.",
        "er": "E-mail obbligatoria.",
        "cr": "La città è obbligatoria.",
        "pr": "Il CAP è obbligatorio.",
        "sr": "Lo stato è obbligatorio.",
        "nr": "Il nome è obbligatorio.",
        "cor": "Il paese è obbligatorio."
    },
    "Latest250114": {
        "Viewdetails":'Visualizza dettagli',
        "Thanksforyourpayment": "Grazie per il tuo pagamento",
        "Bankname": "Nome della banca",
        "Alipayselected": "Alipay selezionato.",
        "WeChatPayselected": "WeChat Pay selezionato.",
        "Asyoctpu": "Dopo aver inviato il tuo ordine, completa il pagamento con",
        "Asyostqcu": "Dopo aver inviato il tuo ordine, scansiona il codice QR con",
        "Starttrial": "Inizia la prova",
        "Subscribe": "Abbonati",
        "Continue": "Continua",
        "NewMemberCoupon": "Coupon per nuovi membri",
        "Addpromotioncode": "Aggiungi codice promozionale",
        "Apply": "Applica",
        "Subscribeto": "Abbonati a",
        "free": " gratuito ",
        "days": " giorni ",
        "day": " giorno ",
        "Then": " Poi ",
        "forthefirstbilling": "per il primo ciclo di fatturazione ",
        "cycles": " cicli ",
        "cycle": " ciclo ",
        "off": " di sconto ",
        "today": " oggi ",
        "Totalaftertrial": "Totale dopo il periodo di prova",
        "Totalduetoday": "Totale dovuto oggi",
        "after": " dopo "
    }
}
