export default {
    "Shopping": {
        "pay": "Payer",
        "otherproduct": "autre produit",
        "otherproducts": "autres produits",
        "qty": "Quantité",
        "patpat": "Acheter sur PatPat",
        "each": "chaque",
        "showall": "Afficher tout",
        "items": "articles",
        "showless": "Afficher moins",
        "subtotal": "Sous-total",
        "totaldue": "Total dû",
        "cashier": "Caissier",
        "and": "et"
    },
    "LeftCard": {
        "back": "Retour",
        "poweredby": "Propulsé par",
        "terms": "Conditions",
        "privacy": "Confidentialité"
    },
    "TopCard": {
        "details": "Détails",
        "close": "Fermer"
    },
    "PaymentMethod": {
        "paymentmethod": "Moyen de paiement"
    },
    "ContactInformation": {
        "contactinformation": "Informations de contact",
        "shippingaddress": "Adresse de livraison",
        "country": "Pays",
        "address1": "Ligne d'adresse 1",
        "address2": "Ligne d'adresse 2",
        "name": "Nom",
        "billingaddress": "Adresse de facturation",
        "phone": "Téléphone",
        "email": "E-mail",
        "FullName": "Nom complet",
        "CPFNumber": "Numéro CPF"
    },
    "ButtunMessage": {
        "processing": "Traitement...",
        "wechatPC": "Création d'un code QR pour",
        "pay": "Payer"
    },
    "ErrorMessage": {
        "confirm": "Confirmer",
        "FullNameerr": "Le nom complet est requis.",
        "CPFNumbererr": "Le numéro CPF est requis.",
        "Countryerr": "Le pays est requis.",
        "CPFIerr": "Veuillez suivre le format : XXX.XXX.XXX-XX ou 11 chiffres sans tirets.",
        "CPFIVerr": "Numéro CPF invalide. Veuillez vérifier et réessayer."
    },
    "Pix": {
        "ScanPix": "Scannez ou copiez le code pour payer avec Pix",
        "tip": "Après avoir passé votre commande, scannez le code QR avec l'application de votre banque ou copiez le code pour payer.",
        "Scanapp": "Scannez pour payer avec l'application bancaire",
        "pixcodetip": "Collez le code Pix sous 'Copier-coller Pix' sur le portail bancaire en ligne ou dans l'application bancaire.",
        "pix_centip": "Après un paiement réussi, la confirmation du fournisseur est requise. Ce processus peut prendre quelques minutes.",
        "pix_copytip": "Ou copiez le code pour payer",
        "pleasehtml": "Veuillez effectuer le paiement dans les"
    },
    "Copy": {
        "success": "Copié dans le presse-papiers",
        "copy": "Copier"
    },
    "CardInformation": {
        "CardInformation": "Informations sur la carte",
        "NameOnCard": "Nom sur la carte",
        "Name": "Nom",
        "Nameerr": "Le nom sur la carte est requis.",
        "Expirationerr": "La date d'expiration de la carte n'est pas complète.",
        "InvalidExpiration": "Date d'expiration invalide.",
        "pastExpiration": "La date d'expiration de la carte est dépassée.",
        "Carderr": "Le numéro de carte n'est pas complet.",
        "NumberErr": "Numéro de carte invalide.",
        "cvcerr": "Le code de sécurité de la carte n'est pas complet.",
        "InvalidCVCCode": "Code CVC invalide."
    },
    "BillingAddress": {
        "BillingAddress": "Adresse de facturation",
        "BS": "L'adresse de facturation est identique à l'adresse de livraison",
        "AL1": "Ligne d'adresse 1",
        "AL2": "Ligne d'adresse 2",
        "City": "Ville",
        "Postalcode": "Code postal",
        "State": "État",
        "Email": "E-mail",
        "Phone": "Téléphone",
        "Phonenumber": "Numéro de téléphone"
    },
    "ShippingAddress": {
        "Name": "Nom",
        "AL1": "Ligne d'adresse 1",
        "AL2": "Ligne d'adresse 2",
        "City": "Ville",
        "Postalcode": "Code postal",
        "State": "État",
        "ShippingAddress": "Adresse de livraison"
    },
    "FormErr": {
        "l1": "La ligne 1 est requise.",
        "l2": "La ligne 2 est requise.",
        "Phoneerr": "Le téléphone est requis.",
        "ie": "E-mail invalide.",
        "er": "L'e-mail est requis.",
        "cr": "La ville est requise.",
        "pr": "Le code postal est requis.",
        "sr": "L'état est requis.",
        "nr": "Le nom est requis.",
        "cor": "Le pays est requis."
    },
    "Latest250114": {
        "Viewdetails":'Voir les détails',
        "Thanksforyourpayment": "Merci pour votre paiement",
        "Bankname": "Nom de la banque",
        "Alipayselected": "Alipay sélectionné.",
        "WeChatPayselected": "WeChat Pay sélectionné.",
        "Asyoctpu": "Après avoir passé votre commande, finalisez le paiement avec",
        "Asyostqcu": "Après avoir passé votre commande, scannez le code QR avec",
        "Starttrial": "Commencer l'essai",
        "Subscribe": "S'abonner",
        "Continue": "Continuer",
        "NewMemberCoupon": "Coupon de nouveau membre",
        "Addpromotioncode": "Ajouter un code promotionnel",
        "Apply": "Appliquer",
        "Subscribeto": "S'abonner à",
        "free": " gratuit ",
        "days": " jours ",
        "day": " jour ",
        "Then": " Ensuite ",
        "forthefirstbilling": "pour le premier cycle de facturation ",
        "cycles": " cycles ",
        "cycle": " cycle ",
        "off": " de réduction ",
        "today": " aujourd'hui ",
        "Totalaftertrial": "Total après l'essai",
        "Totalduetoday": "Total dû aujourd'hui",
        "after": " après "
    }
}
