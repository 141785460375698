export default {
    "Shopping": {
      "pay": "Thanh toán",
      "otherproduct": "sản phẩm khác",
      "otherproducts": "các sản phẩm khác",
      "qty": "Số lượng",
      "patpat": "Mua trên PatPat",
      "each": "mỗi",
      "showall": "Hiển thị tất cả",
      "items": "sản phẩm",
      "showless": "Thu gọn",
      "subtotal": "Tổng phụ",
      "totaldue": "Tổng cần thanh toán",
      "cashier": "Thu ngân",
      "and": "và"
    },
    "LeftCard": {
      "back": "Quay lại",
      "poweredby": "Được hỗ trợ bởi",
      "terms": "Điều khoản",
      "privacy": "Quyền riêng tư"
    },
    "TopCard": {
      "details": "Chi tiết",
      "close": "Đóng"
    },
    "PaymentMethod": {
      "paymentmethod": "Phương thức thanh toán"
    },
    "ContactInformation": {
      "contactinformation": "Thông tin liên hệ",
      "shippingaddress": "Địa chỉ giao hàng",
      "country": "Quốc gia",
      "address1": "Địa chỉ dòng 1",
      "address2": "Địa chỉ dòng 2",
      "name": "Tên",
      "billingaddress": "Địa chỉ thanh toán",
      "phone": "Điện thoại",
      "email": "Email",
      "FullName": "Họ và tên đầy đủ",
      "CPFNumber": "Số CPF"
    },
    "ButtunMessage": {
      "processing": "Đang xử lý...",
      "wechatPC": "Tạo mã QR cho",
      "pay": "Thanh toán"
    },
    "ErrorMessage": {
      "confirm": "Xác nhận",
      "FullNameerr": "Họ và tên đầy đủ là bắt buộc.",
      "Countryerr": "Vui lòng nhập quốc gia",
      "CPFNumbererr": "Số CPF là bắt buộc.",
      "CPFIerr": "Vui lòng nhập theo định dạng: XXX.XXX.XXX-XX hoặc 11 chữ số không có dấu gạch.",
      "CPFIVerr": "Số CPF không hợp lệ. Vui lòng kiểm tra và nhập lại.",
    },
    "Pix": {
      "ScanPix": "Quét hoặc sao chép mã để thanh toán bằng Pix",
      "tip": "Sau khi gửi đơn hàng của bạn, quét mã QR bằng ứng dụng ngân hàng của bạn hoặc sao chép mã để thanh toán.",
      "Scanapp": "Quét để thanh toán bằng ứng dụng ngân hàng của bạn",
      "pixcodetip": "Dán mã Pix dưới mục 'Sao chép và dán Pix' trong cổng ngân hàng trực tuyến hoặc ứng dụng ngân hàng của bạn",
      "pix_centip": "Sau khi thanh toán thành công, cần xác nhận từ nhà cung cấp. Quá trình này có thể mất vài phút.",
      "pix_copytip": "Hoặc sao chép mã để thanh toán",
      "pleasehtml": "Vui lòng thanh toán tại"
    },
    "Copy": {
      "success": "Đã sao chép vào clipboard",
      "copy": "Sao chép"
    },
    "CardInformation": {
      "CardInformation": "Thông tin thẻ",
      "NameOnCard": "Tên trên thẻ",
      "Name": "Tên",
      "Nameerr": "Yêu cầu tên trên thẻ.",
      "Expirationerr": "Ngày hết hạn của thẻ chưa đầy đủ.",
      "InvalidExpiration": "Ngày hết hạn không hợp lệ.",
      "pastExpiration": "Ngày hết hạn của thẻ đã qua.",
      "Carderr": "Số thẻ chưa đầy đủ.",
      "NumberErr": "Số thẻ không hợp lệ",
      "cvcerr": "Mã bảo mật của thẻ của bạn chưa đầy đủ.",
      "InvalidCVCCode": "Mã CVC không hợp lệ."
  },
  "BillingAddress": {
      "BillingAddress": "Địa chỉ thanh toán",
      "BS": "Địa chỉ thanh toán giống địa chỉ giao hàng",
      "AL1": "Địa chỉ dòng 1",
      "AL2": "Địa chỉ dòng 2",
      "City": "Thành phố",
      "Postalcode": "Mã bưu chính",
      "State": "Tiểu bang",
      "Email": "Email",
      "Phone": "Điện thoại",
      "Phonenumber": "Số điện thoại"
  },
  "ShippingAddress": {
      "Name": "Tên",
      "AL1": "Địa chỉ dòng 1",
      "AL2": "Địa chỉ dòng 2",
      "City": "Thành phố",
      "Postalcode": "Mã bưu chính",
      "State": "Tiểu bang",
      "ShippingAddress": "Địa chỉ giao hàng"
  },
  "CountyCode": {
      "CountyUrl": []
  },
  "FormErr": {
    "l1": "Yêu cầu địa chỉ dòng 1.",
    "l2": "Yêu cầu địa chỉ dòng 2.",
    "Phoneerr": "Yêu cầu số điện thoại.",
    "ie": "Email không hợp lệ.",
    "er": "Yêu cầu email.",
    "cr": "Yêu cầu thành phố.",
    "pr": "Yêu cầu mã bưu chính.",
    "sr": "Yêu cầu tiểu bang.",
    "nr": "Yêu cầu tên.",
    "cor": "Yêu cầu quốc gia."
} ,
Latest250114:{
    "Viewdetails":'Xem chi tiết',
    'Thanksforyourpayment': 'Cảm ơn bạn đã thanh toán',
    'Bankname': 'Tên ngân hàng',
    'Alipayselected': 'Đã chọn Alipay.',
    'WeChatPayselected': 'Đã chọn WeChat Pay.',
    'Asyoctpu': 'Sau khi gửi đơn đặt hàng, hãy hoàn tất thanh toán bằng',
    'Asyostqcu': 'Sau khi gửi đơn đặt hàng, hãy quét mã QR bằng',
    'Starttrial': 'Bắt đầu dùng thử',
    'Subscribe': 'Đăng ký',
    'Continue': 'Tiếp tục',
    'NewMemberCoupon': 'Phiếu giảm giá thành viên mới',
    'Addpromotioncode': 'Thêm mã khuyến mãi',
    'Apply': 'Áp dụng',
    'Subscribeto': 'Đăng ký với',
    'free': ' miễn phí ',
    'days': ' ngày ',
    'day': ' ngày ',
    'Then': ' Sau đó ',
    'forthefirstbilling': 'cho lần thanh toán đầu tiên',
    'cycles': ' chu kỳ ',
    'cycle': ' chu kỳ ',
    'off': ' giảm ',
    'today': ' hôm nay ',
    'Totalaftertrial': 'Tổng sau khi thử nghiệm',
    'Totalduetoday': 'Tổng số tiền cần thanh toán hôm nay',
    "after": ' sau '
},
  }  